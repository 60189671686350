import { cutoffHour, cutoffMinutes } from '../../../config/config/settings';
import skippedHolidays from '../../../config/helpers/skippedHolidays';
import { DateTime } from 'luxon';

function isUSHoliday(date) {
    // 'date' is a Luxon DateTime object in 'America/Chicago' time zone
    const year = date.year;
    const month = date.month;
    const day = date.day;
    const weekday = date.weekday; // 1 = Monday, 7 = Sunday

    // New Year's Day
    if (month === 1 && day === 1) return true;

    // Memorial Day: Last Monday in May
    const memorialDay = DateTime.fromObject({ year, month: 5, day: 31 }, { zone: 'America/Chicago' })
        .startOf('day')
        .minus({ days: (DateTime.fromObject({ year, month: 5, day: 31 }, { zone: 'America/Chicago' }).weekday % 7) });
    if (date.hasSame(memorialDay, 'day')) return true;

    // Juneteenth National Independence Day
    if (month === 6 && day === 19) return true;

    // Independence Day
    if (month === 7 && day === 4) return true;

    // Labor Day: First Monday in September
    const laborDay = DateTime.fromObject({ year, month: 9, day: 1 }, { zone: 'America/Chicago' })
        .startOf('day')
        .plus({ days: (8 - DateTime.fromObject({ year, month: 9, day: 1 }, { zone: 'America/Chicago' }).weekday) % 7 });
    if (date.hasSame(laborDay, 'day')) return true;

    // // Columbus Day: Second Monday in October
    // const firstMondayInOctober = DateTime.fromObject({ year, month: 10, day: 1 }, { zone: 'America/Chicago' })
    //     .startOf('day')
    //     .plus({ days: (8 - DateTime.fromObject({ year, month: 10, day: 1 }, { zone: 'America/Chicago' }).weekday) % 7 });
    // const columbusDay = firstMondayInOctober.plus({ days: 7 });
    // if (date.hasSame(columbusDay, 'day')) return true;

    // // Veterans Day
    // if (month === 11 && day === 11) return true;

    // Thanksgiving Day: Fourth Thursday in November
    const firstThursdayInNovember = DateTime.fromObject({ year, month: 11, day: 1 }, { zone: 'America/Chicago' })
        .startOf('day')
        .plus({ days: (11 - DateTime.fromObject({ year, month: 11, day: 1 }, { zone: 'America/Chicago' }).weekday) % 7 });
    const thanksgivingDay = firstThursdayInNovember.plus({ days: 21 });
    if (date.hasSame(thanksgivingDay, 'day')) return true;

    // Christmas Day
    if (month === 12 && day === 25) return true;

    // Not a holiday
    return false;
}

function latestCutoff(day = DateTime.now().setZone('America/Chicago')) {

    // Convert input to Luxon DateTime in Central Time
    if (typeof day === 'string') {
        // Assuming the input string is in 'YYYY-MM-DD HH:mm' format
        day = DateTime.fromFormat(day, 'yyyy-MM-dd HH:mm', { zone: 'America/Chicago' });
    } else if (day instanceof Date) {
        day = DateTime.fromJSDate(day, { zone: 'America/Chicago' });
    } else if (day instanceof DateTime) {
        day = day.setZone('America/Chicago');
    } else {
        day = new Date(day);
        day = DateTime.fromJSDate(day).setZone('America/Chicago');
    }

    // Determine if before or after cutoff time
    const currentHour = day.hour;
    const currentMinute = day.minute;

    let targetDate;
    if (
        currentHour < cutoffHour ||
        (currentHour === cutoffHour && currentMinute < cutoffMinutes)
    ) {
        // Before cutoff time: use previous day
        targetDate = day.minus({ days: 1 }).startOf('day');
    } else {
        // After cutoff time: use current day
        targetDate = day.startOf('day');
    }

    // Set cutoff time on target date
    let startRange = targetDate.set({
        hour: cutoffHour,
        minute: cutoffMinutes,
        second: 0,
        millisecond: 0,
    });

    // Adjust to the latest business day
    while (true) {
        const isSunday = startRange.weekday === 7; // 7 = Sunday
        const isHoliday = isUSHoliday(startRange);
        if (!isSunday && !isHoliday) {
            break; // It's a business day
        }
        // Move to previous day
        startRange = startRange.minus({ days: 1 });
    }

    // Return as a JavaScript Date object or Luxon DateTime, as needed
    return startRange.toJSDate();
}

export default latestCutoff;
